/* Animation for Fade In */
@keyframes fadeInAnimation {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Animation for Fade Out */
@keyframes fadeOutAnimation {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

/* Apply fade-in animation when modal opens */
.modalFadeEnter {
  animation: fadeInAnimation 0.3s forwards;
}

/* Apply fade-out animation when modal closes */
.modalFadeExit {
  animation: fadeOutAnimation 0.3s forwards;
}
